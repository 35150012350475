import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore } from 'angularfire2/firestore';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.css']
})
export class ShiftComponent implements OnInit {
  shiftFormGroup: FormGroup;
  shifts: any[];
  managersNumber: boolean[];
  users: any[];

  constructor(private formBuilder: FormBuilder,
              private afs: AngularFirestore,
              private snackbar: MatSnackBar,
              private translate: TranslateService,
              private app: AppService) {
    this.shiftFormGroup = this.formBuilder.group({
      shift: null
    });
  }

  ngOnInit() {
    this.afs.collection('plants').doc(this.app.plantId)
      .collection('shifts', ref => ref.orderBy('label')).snapshotChanges().subscribe(shifts => {
      this.shifts = shifts.map(shift => {
        return {
          id: shift.payload.doc.id,
          ...shift.payload.doc.data()
        };
      });

      if (! this.shiftFormGroup.get('shift').value) {
        this.shiftFormGroup.get('shift').setValue(this.shifts[0]['id']);
      }
    });

    this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges()
      .subscribe(dbUsers => {
        this.users = dbUsers.map(user => {
          return {
            id: user.payload.doc.id,
            ...user.payload.doc.data()
          };
        });
      });

    this.shiftFormGroup.get('shift').valueChanges.subscribe(selectedShiftId => {
      Object.keys(this.shiftFormGroup.value)
        .filter(key => key.indexOf('manager') === 0)
        .map(key => this.shiftFormGroup.removeControl(key));

      const selectedShift = this.shifts.filter(shift => shift['id'] === selectedShiftId)[0];

      if (selectedShift['managers'] && selectedShift['managers'].length > 0) {
        this.managersNumber = selectedShift['managers'].map(() => true);
        selectedShift['managers'].map((manager, index) => {
          this.shiftFormGroup.addControl('manager' + index, new FormControl());
          this.shiftFormGroup.get('manager' + index).setValue(manager['id']);
        });
      } else {
        this.managersNumber = [true];
        this.shiftFormGroup.addControl('manager0', new FormControl());
      }
    });
  }

  saveShift = () => {
    const managers = Object.keys(this.shiftFormGroup.value)
      .filter(key => key.indexOf('manager') === 0 && this.shiftFormGroup.get(key).value)
      .map(key => this.users.filter(user => user.id === this.shiftFormGroup.get(key).value)[0])
      .map(user => {
        return {
          id: user.id,
          fullname: user.fullname,
          email: user.email
        };
      });

    const shiftId = this.shiftFormGroup.get('shift').value;

      this.afs.collection('plants').doc(this.app.plantId)
        .collection('shifts').doc(this.shiftFormGroup.get('shift').value)
        .update({
          managers
        })
        .then(() => {
          this.translate.get('SHIFT_UPDATED')
            .subscribe(snackbarTranslation => {
              this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
            });
        });
  }

  addManagerControl = () => {
    this.managersNumber.push(true);
    this.shiftFormGroup.addControl('manager' + (this.managersNumber.length - 1), new FormControl());
  }
}
