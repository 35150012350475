import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { AppService } from './app.service';
import { UserManagementService } from './authentication/user-management.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isAdmin$: Observable<boolean>;

  constructor(private router: Router,
              private translate: TranslateService,
              private afs: AngularFirestore,
              private userManagement: UserManagementService,
              public afAuth: AngularFireAuth,
              public app: AppService) {

    this.afs.collection<any>('languages').valueChanges()
      .subscribe(languages => {
        const codes = languages.map(language => language.code);
        this.translate.addLangs(codes);
        this.translate.setDefaultLang('en');

        if (localStorage.getItem('lang') && codes.includes(localStorage.getItem('lang'))) {
          this.translate.use(localStorage.getItem('lang'));
        } else {
          const browserLang = translate.getBrowserLang();
          this.translate.use(codes.includes(browserLang) ? browserLang : 'en');
        }
      });

    this.isAdmin$ = this.userManagement.user.pipe(
      map(user => {
        if (user['admin']) {
          return (user['admin'].indexOf(this.app.plantId) > -1);
        } else {
          return false;
        }
      })
    );
  }

  goToHome = () => {
    this.router.navigate([this.app.plantId + '/observation']);
  }

  logout = () => {
    this.afAuth.auth.signOut();
    this.router.navigate(['login']);
  }
}
