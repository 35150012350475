import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireFunctions } from 'angularfire2/functions';
import { AngularFireAuth } from 'angularfire2/auth';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
  @Input() layout: string;
  @Input() id: Subject<string>;
  userFormGroup: FormGroup;
  users: any[];
  editedId: string;

  constructor(private formBuilder: FormBuilder,
              private afs: AngularFirestore,
              private aff: AngularFireFunctions,
              private afAuth: AngularFireAuth,
              private snackbar: MatSnackBar,
              private translate: TranslateService) {
    this.userFormGroup = this.formBuilder.group({
      fullname: [null, Validators.required] ,
      email: [null, Validators.email],
      password: [{value: null, disabled: true}, Validators.required],
      isAppUser: [{value: false, disabled: true}], // FIXME: disabled because app user creation not working.
      manager: [null]
    });
  }

  ngOnInit() {
    this.userFormGroup.get('isAppUser').valueChanges.subscribe(isAppUser => {
      if (isAppUser) {
        this.userFormGroup.get('password').enable();
      } else {
        this.userFormGroup.get('password').disable();
      }
    });

    this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges()
      .subscribe(dbUsers => {
        this.users = dbUsers.map(user => {
          return {
            id: user.payload.doc.id,
            email: user.payload.doc.data()['email'],
            fullname: user.payload.doc.data()['fullname']
          };
        });
      });

    this.id.subscribe(id => {
      this.afs.collection('users').doc(id).valueChanges()
        .subscribe(dbUser => {
          this.editedId = id;
          this.userFormGroup.get('fullname').setValue(dbUser['fullname']);
          this.userFormGroup.get('email').setValue(dbUser['email']);
          if (dbUser['manager']) {
            this.userFormGroup.get('manager').setValue(dbUser['manager']);
          }
        });
    });
  }

  addUser = () => {
    const newUser = {
      email: this.userFormGroup.get('email').value,
      fullname: this.userFormGroup.get('fullname').value
    };

    if (this.userFormGroup.get('manager').value) {
      newUser['manager'] = this.userFormGroup.get('manager').value;
    }

    if (!this.editedId) {
      this.afs.collection('users').add(newUser)
      .then(() => {
        this.translate.get('USER_ADDED')
          .subscribe(snackbarTranslation => {
            this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
          });
      });
    } else {
      this.afs.collection('users').doc(this.editedId).update(newUser)
      .then(() => {
        this.translate.get('USER_UPDATED')
          .subscribe(snackbarTranslation => {
            this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
          });
      });
    }

    /*if (this.userFormGroup.get('isAppUser').value) {
      newUser['password'] = this.userFormGroup.get('password').value;

      const config = {
        apiKey: 'apiKey',
        authDomain: 'projectId.firebaseapp.com',
        databaseURL: 'https://databaseName.firebaseio.com'
      };
      const secondaryApp = firebase.initializeApp(config, 'Secondary');

      secondaryApp.auth.createUserWithEmailAndPassword(newUser['email'], newUser['password'])
        .then(res => {
          console.log(res);
          this.afs.collection('users').doc(res.user.uid).set(newUser)
            .then(() => {
              this.translate.get('USER_ADDED')
                .subscribe(snackbarTranslation => {
                  this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
                });
            });
        });
      // this.aff.functions.httpsCallable('createUser')(newUser).then(res => console.log(res));
      // this.aff.httpsCallable('creatUser')(newUser).subscribe(res => console.log(res));
      this.aff.httpsCallable('createUser')(newUser).pipe(
        switchMap(user => {
          return this.afs.collection('users').doc(user.id).set(newUser);
        })
      ).subscribe(res => {
        console.log(res);
      });



    } else {
      this.afs.collection('users').add(newUser)
        .then(() => {
          this.translate.get('USER_ADDED')
            .subscribe(snackbarTranslation => {
              this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
            });
        });
    }*/
  }

  clearUser = () => {
    this.userFormGroup.reset();
    this.editedId = null;
  }
}
