import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../authentication/auth-guard.service';
import { StaffComponent } from './staff/staff.component';
import { DepartmentComponent } from './department/department.component';
import { ShiftComponent } from './shift/shift.component';
import { UserComponent } from './user/user.component';

const routes: Routes = [
  { path: 'administration',
    canActivate: [AuthGuardService],
    children: [
      { path: 'staff',
        component: StaffComponent,
        children: [
          { path: '', redirectTo: 'department', pathMatch: 'full'},
          { path: 'department', component: DepartmentComponent },
          { path: 'shift', component: ShiftComponent },
          { path: 'user', component: UserComponent }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
