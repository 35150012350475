import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  // @Input() layout: string;
  // userFormGroup: FormGroup;
  // users$: Observable<any[]>;
  id$: Subject<string>;

  constructor(private formBuilder: FormBuilder,
              private afs: AngularFirestore) {
    this.id$ = new Subject();
    /* this.userFormGroup = this.formBuilder.group({
      fullname: [null, Validators.required] ,
      email: [null, Validators.email],
      password: [{value: null, disabled: true}, Validators.required],
      isAppUser: false,
      manager: [null]
    }); */
  }

  ngOnInit() {
    /* this.userFormGroup.get('isAppUser').valueChanges.subscribe(isAppUser => {
      if (isAppUser) {
        this.userFormGroup.get('password').enable();
      } else {
        this.userFormGroup.get('password').disable();
      }
    });

    this.users$ = this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges().pipe(
      map(users => {
        return users.map(user => {
          return {
            id: user.payload.doc.id,
            ...user.payload.doc.data()
          };
        });
      })
    ); */
  }

  editUser = (event, el: HTMLElement) => {
    this.id$.next(event);
    el.scrollIntoView();
  }

  /* getUser = (event) => {
    console.log(event);
    console.log(this.userFormGroup.get('manager').value);
  }

  addUser = () => {

  } */
}
