import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule } from '../material-design/material-design.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AdministrationRoutingModule } from './administration-routing.module';
import { StaffComponent } from './staff/staff.component';
import { UserComponent } from './user/user.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { DepartmentComponent } from './department/department.component';
import { ShiftComponent } from './shift/shift.component';
import { UserAddComponent } from './user/user-add/user-add.component';
import { UserListComponent } from './user/user-list/user-list.component';

@NgModule({
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [StaffComponent, UserComponent, UserSelectComponent, DepartmentComponent, ShiftComponent, UserAddComponent,
    UserListComponent]
})
export class AdministrationModule { }
