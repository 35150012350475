import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { switchMap, filter, delay, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './../../app.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { ConfirmDialogComponent } from '../../material-design/confirm-dialog/confirm-dialog.component';

@Component({
  templateUrl: './observation-edit.component.html',
  styleUrls: ['./observation-edit.component.css']
})
export class ObservationEditComponent implements OnInit, OnDestroy {
  observationHeader;
  observations;
  id;
  plantId;
  users$;
  departments$;
  shifts$;
  types$;
  categories$;
  situations;
  commitment;
  observationsHeaderFormGroup: FormGroup;
  observationFormGroup: FormGroup;
  addObservation$;
  saveObservation$;

  constructor(private route: ActivatedRoute,
              private afs: AngularFirestore,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private translate: TranslateService,
              private app: AppService,
              public router: Router) {

    this.observationsHeaderFormGroup = this.formBuilder.group({
      editor: [null, Validators.required],
      attendant: null,
      department: [null, Validators.required],
      shift: [null, Validators.required],
      date: [null, Validators.required],
      duration: null,
      task: null
    });

    this.observationFormGroup = this.formBuilder.group({
      type: [null, Validators.required],
      category: [null, Validators.required],
      situation: null,
      commitment: null,
      description: null
    });
  }

  ngOnInit() {
    this.observations = [];

    this.users$ = this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges().pipe(
      map(dbUsers => {
        return  dbUsers.map(user => {
          return {
            id: user.payload.doc.id,
            ...user.payload.doc.data()
          };
        });
      }));

    this.types$ = this.afs.collection('observation_types').valueChanges();
    /*this.types$ = this.afs.collection('observation_types').valueChanges().pipe(
      switchMap(observationTypes => {
        return combineLatest(observationTypes.map(observationType => {
          return this.afs.collection('observation_types').doc(observationType['code']).collection('categories').valueChanges().pipe(
            map(categories => {
              return {
                label: observationType['label'],
                code: observationType['code'],
                categories
              };
            })
          );
        }));
      }));*/

    this.route.params.pipe(
      delay(0),
      switchMap(params => {
        this.app.showProgressBar();
        this.plantId = params.plantId;
        this.departments$ = this.afs.collection('plants').doc(params.plantId)
          .collection('departments', ref => ref.orderBy('label')).snapshotChanges().pipe(
            map(dbDepartments => {
              return  dbDepartments.map(department => {
                return {
                  id: department.payload.doc.id,
                  ...department.payload.doc.data()
                };
              });
            }));
        this.shifts$ = this.afs.collection('plants').doc(params.plantId).collection('shifts', ref => ref.orderBy('label'))
          .snapshotChanges().pipe(
            map(dbShifts => {
              return  dbShifts.map(shift => {
                return {
                  id: shift.payload.doc.id,
                  ...shift.payload.doc.data()
                };
              });
            }));
        return combineLatest(
          this.departments$,
          this.shifts$,
          this.route.params
        );
      }),
      filter(([departments, shifts, params]) => {
        if (!params.id) {
          this.app.showProgressBar(false);
        }
        return params.id;
      }),
      switchMap(([departments, shifts, params]) => {
        this.id = params.id;
        return combineLatest(
          this.afs.collection('observations').doc(params.id).valueChanges(),
          this.afs.collection('observation_types').valueChanges().pipe(
            switchMap(observationTypes => {
              return combineLatest(observationTypes.map(observationType => {
                return this.afs.collection('observation_types').doc(observationType['code']).collection('categories').valueChanges().pipe(
                  map(categories => {
                    return {
                      label: observationType['label'],
                      code: observationType['code'],
                      categories
                    };
                  })
                );
              }));
            })
          )
        );
      }))
      .subscribe(([observationList, observationTypes]) => {
        this.app.showProgressBar(false);
        this.observationsHeaderFormGroup.patchValue({
          editor: (observationList['editor']) ? observationList['editor']['id'] : null,
          attendant: (observationList['attendant']) ? observationList['attendant']['id'] : null,
          department: (observationList['department']) ? observationList['department']['id'] : null,
          shift: (observationList['shift']) ? observationList['shift']['id'] : null,
          date: (observationList['date']) ? new Date(observationList['date']['seconds'] * 1000) : null,
          duration: (observationList['duration']) ? observationList['duration'] : null,
          task: (observationList['task']) ? observationList['task'] : null
        });
        this.observations = observationList['observations'].map(detail => {
          detail['typeLabel'] = observationTypes.filter(type => type['code'] === detail.type).map(type => {
            detail['categoryLabel'] = type['categories'].filter(category => category['code'] === detail.category)
              .map(category => category['label']);
            return type['label'];
          });

          return detail;
        });
      });

    this.observationFormGroup.get('type').valueChanges.pipe(
      filter(type => type),
      switchMap(selectedType => {
        this.categories$ = this.afs.collection('observation_types').doc(selectedType).collection('categories').valueChanges();
        this.observationFormGroup.get('situation').setValue(null);
        this.observationFormGroup.get('commitment').setValue(null);
        return combineLatest(
          this.afs.collection('observation_types').doc(selectedType).collection('categories').valueChanges(),
          this.observationFormGroup.get('category').valueChanges
        );
      }))
      .subscribe(([categories, selectedCategoryCode]) => {
        if (selectedCategoryCode) {
          const selectedCategory = categories.filter(category => category.code === selectedCategoryCode)[0];
          this.situations = (selectedCategory.situations) ? selectedCategory.situations : [];
          this.commitment = selectedCategory.commitment;
        } else {
          this.situations = [];
          this.commitment = null;
        }
      });
  }

  ngOnDestroy() {
    if (this.saveObservation$) {
      this.saveObservation$.unsubscribe();
    }
    // this.users$.unsubscribe();
    // this.departments$.unsubscribe();
    // this.shifts$.unsubscribe();
    // this.types$.unsubscribe();
    // this.categories$.unsubscribe();
  }

  addObservation = () => {
    this.addObservation$ = this.afs.collection('observation_types').valueChanges().pipe(
      switchMap(observationTypes => {
        return combineLatest(observationTypes.map(observationType => {
          return this.afs.collection('observation_types').doc(observationType['code']).collection('categories').valueChanges().pipe(
            map(categories => {
              return {
                label: observationType['label'],
                code: observationType['code'],
                categories
              };
            })
          );
        }));
      })
    ).subscribe(observationTypes => {
      const observation = this.observationFormGroup.value;
      observation['typeLabel'] = observationTypes.filter(type => type['code'] === observation.type).map(type => {
        observation['categoryLabel'] = type['categories'].filter(category => category['code'] === observation.category)
          .map(category => category['label']);
        return type['label'];
      });

      (this.observations = this.observations || []).push(observation);
      this.observationFormGroup.reset();
    });

    // (this.observations = this.observations || []).push(this.observationFormGroup.value);
    // this.observationFormGroup.reset();
  }

  deleteObservation = (index) => {
    this.translate.get('DELETE_OBSERVATION')
      .subscribe(dialogTranslation => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: { title: dialogTranslation + ' ?' }
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response === 'y') {
            this.observations.splice(index, 1);
          }
        });
      });
  }

  saveObservations = () => {
    this.saveObservation$ = combineLatest(
      this.afs.collection('users').snapshotChanges().pipe(
        map(dbUsers => {
          return  dbUsers.map(user => {
            return {
              id: user.payload.doc.id,
              ...user.payload.doc.data()
              /* fullname: user.payload.doc.data()['fullname'],
              email: user.payload.doc.data()['email'] */
            };
          });
        })),
      this.afs.collection('plants').doc(this.plantId).collection('departments').snapshotChanges().pipe(
        map(dbDepartments => {
          return  dbDepartments.map(department => {
            return {
              id: department.payload.doc.id,
              ...department.payload.doc.data()
            };
          });
        })),
      this.afs.collection('plants').doc(this.plantId).collection('shifts').snapshotChanges().pipe(
        map(dbShifts => {
          return  dbShifts.map(shift => {
            return {
              id: shift.payload.doc.id,
              ...shift.payload.doc.data()
            };
          });
        }))
    )
      .subscribe(([users, departments, shifts]) => {
        const observationList = {
          editor: users.filter(user => user.id === this.observationsHeaderFormGroup.get('editor').value)[0],
          department: departments.filter(department => department.id === this.observationsHeaderFormGroup.get('department').value)[0],
          shift: shifts.filter(shift => shift.id === this.observationsHeaderFormGroup.get('shift').value)[0],
          duration: this.observationsHeaderFormGroup.get('duration').value,
          task: this.observationsHeaderFormGroup.get('task').value,
          plantId: this.plantId,
          observations: this.observations.map(observation => {
            return observation;
            /* const {categoryLabel, typeLabel, ...dbObservation} = observation;
            return dbObservation; */
          })
        };

        if (this.observationsHeaderFormGroup.get('date').value) {
          observationList['date'] = new Date(this.observationsHeaderFormGroup.get('date').value);
        }

        if (users.filter(user => user.id === this.observationsHeaderFormGroup.get('attendant').value).length > 0) {
          observationList['attendant'] = users.filter(user => user.id === this.observationsHeaderFormGroup.get('attendant').value)[0];
        }

        if (this.id) {
          this.afs.collection('observations').doc(this.id).update(observationList)
            .then(() => {
              this.translate.get('OBSERVATION_UPDATED')
                .subscribe(snackbarTranslation => {
                  this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
                });
            })
            .catch(error => {
              this.translate.get('ERROR_SAVING')
                .subscribe(snackbarTranslation => {
                  this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
                });
            });
            this.router.navigate(['../../'], { relativeTo: this.route });
        } else {
          this.afs.collection('observations').add(observationList)
          .then(() => {
            this.translate.get('OBSERVATION_ADDED')
              .subscribe(snackbarTranslation => {
                this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
              });
          })
          .catch(error => {
            this.translate.get('ERROR_SAVING')
              .subscribe(snackbarTranslation => {
                this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
              });
          });
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
    /* const observationList = {
      ...this.observationHeader.value,
      plantId: this.plantId,
      observations: this.observations
    };

    if (this.observationHeader.get('date').value) {
      observationList['date'] = new Date(this.observationHeader.get('date').value);
    }

    if (this.id) {
      this.afs.collection('observations').doc(this.id).update(observationList)
        .then(() => {
          this.translate.get('OBSERVATION_UPDATED')
            .subscribe(snackbarTranslation => {
              this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
            });
        })
        .catch(error => {
          this.translate.get('ERROR_SAVING')
            .subscribe(snackbarTranslation => {
              this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
            });
        });
        this.router.navigate(['../../'], { relativeTo: this.route });
    } else {
      this.afs.collection('observations').add(observationList)
      .then(() => {
        this.translate.get('OBSERVATION_ADDED')
          .subscribe(snackbarTranslation => {
            this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
          });
      })
      .catch(error => {
        this.translate.get('ERROR_SAVING')
          .subscribe(snackbarTranslation => {
            this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
          });
      });
      this.router.navigate(['../'], { relativeTo: this.route });
    }*/
  }
}
