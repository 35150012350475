import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';


@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  languages;
  departments;
  plants;
  selectedPlantId: string;
  loginFormGroup: FormGroup;
  env;

  constructor(public translate: TranslateService,
              public afAuth: AngularFireAuth,
              public app: AppService,
              private afs: AngularFirestore,
              private router: Router,
              private formBuilder: FormBuilder) {

    this.env = environment;

    this.loginFormGroup = this.formBuilder.group({
      email: [null, Validators.email ],
      password: [null, Validators.required ],
      plant: [null, Validators.required ],
      language: [null, Validators.required ]
    });
  }

  ngOnInit() {
    this.afAuth.auth.signOut();

    this.afs.collection<any>('languages').valueChanges()
      .subscribe(languages => {
        this.languages = languages;
        this.loginFormGroup.get('language').setValue(this.translate.currentLang);
      });

    this.afs.collection<any>('plants').snapshotChanges()
      .subscribe(plants => {
        this.plants = plants.map(plant => {
          return {
            id: plant.payload.doc.id,
            ...plant.payload.doc.data()
          };
        });

        if (localStorage.getItem('plantId')) {
          this.loginFormGroup.get('plant').setValue(localStorage.getItem('plantId'));
        }
      });

    this.loginFormGroup.get('plant').valueChanges
      .subscribe(id => {
        this.selectedPlantId = id;
        this.app.plantId = id;
        localStorage.setItem('plantId', id);
      });

    this.loginFormGroup.get('language').valueChanges
      .subscribe(language => {
        this.translate.use(language);
        localStorage.setItem('lang', language);
      });
  }

  login = () => {
    this.app.showProgressBar();
    this.loginFormGroup.disable();
    this.afAuth.auth.signInWithEmailAndPassword(this.loginFormGroup.value.email, this.loginFormGroup.value.password)
      .then(res => {
        this.app.showProgressBar(false);
        this.router.navigate([this.selectedPlantId + '/observation']);
      })
      .catch(error => {
        this.app.showProgressBar(false);
        this.loginFormGroup.enable();
        console.log(JSON.stringify(error));
      });
  }
}
