import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { switchMap, map , delay, startWith, distinctUntilChanged} from 'rxjs/operators';
import * as moment from 'moment';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../material-design/confirm-dialog/confirm-dialog.component';
import { UserManagementService } from '../../authentication/user-management.service';
import { AppService } from '../../app.service';

@Component({
  templateUrl: './observation-list.component.html',
  styleUrls: ['./observation-list.component.css']
})
export class ObservationListComponent implements OnInit {
  observations$: Observable<any[]>;
  isAdmin$: Observable<boolean>;
  userEmail$: Observable<any>;
  plantObservations: any[];
  startYear: number;
  years: number[];
  userStats: { observationNumber: number, totalObservationTime: number };
  plantStats: { observationNumber: number, totalObservationTime: number };
  dateFormGroup: FormGroup;

  constructor(public router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private translate: TranslateService,
              private userManagement: UserManagementService,
              private app: AppService) {

    this.startYear = 2017;
    this.years = [];

    while (this.startYear <= moment().year()) {
      this.years.push(this.startYear++);
    }

    this.dateFormGroup = this.formBuilder.group({
      year: moment().year(),
      search: ''
    });

    this.userStats = {
      observationNumber: 0,
      totalObservationTime: 0
    };

    this.plantStats = {
      observationNumber: 0,
      totalObservationTime: 0
    };
    }

  ngOnInit() {
    this.observations$ = combineLatest(
      this.dateFormGroup.get('year').valueChanges.pipe(startWith(moment().year())),
      this.route.params
    ).pipe(
      delay(0),
      switchMap(([year, params]) => {
        this.app.showProgressBar();
        return combineLatest(
          this.afs.collection('observations',
            ref =>
              ref
                .where('plantId', '==', params.plantId).orderBy('date', 'desc')
                .where('date', '>=', moment(year + '-01-01').startOf('day').toDate())
                .where('date', '<', (year !== moment().year()) ? moment(year + '-12-31').endOf('day').toDate() : moment().toDate())
          ).snapshotChanges(),
          this.dateFormGroup.get('search').valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            delay(650)
          ),
          this.afAuth.authState.pipe(
            switchMap(user => this.afs.collection('users', ref => ref.where('email', '==', user.email)).valueChanges())
          ),
          // this.afs.collection('observation_types').valueChanges()
          this.afs.collection('observation_types').valueChanges().pipe(
            switchMap(observationTypes => {
              return combineLatest(observationTypes.map(observationType => {
                return this.afs.collection('observation_types').doc(observationType['code']).collection('categories').valueChanges().pipe(
                  map(categories => {
                    return {
                      label: observationType['label'],
                      code: observationType['code'],
                      categories
                    };
                  })
                );
              }));
            })
          )
        );
      }),
      map(([observations, search, user, observationTypes]) => {
        this.app.showProgressBar(false);
        let filteredObservations = observations.map(observation => {
          let observationDetails = [];
          if (observation.payload.doc.data()['observations']) {
            observationDetails = observation.payload.doc.data()['observations'].map(detail => {
              detail['typeLabel'] = observationTypes.filter(type => type['code'] === detail.type).map(type => {
                detail['categoryLabel'] = type['categories'].filter(category => category['code'] === detail.category)
                  .map(category => category['label']);
                return type['label'];
              });

              return detail;
            });
          }

          return {
            id: observation.payload.doc.id,
            editor: (observation.payload.doc.data()['editor']) ? observation.payload.doc.data()['editor'] : null,
            attendant: (observation.payload.doc.data()['attendant']) ? observation.payload.doc.data()['attendant'] : null,
            department: (observation.payload.doc.data()['department']) ? observation.payload.doc.data()['department'] : null,
            shift: (observation.payload.doc.data()['shift']) ? observation.payload.doc.data()['shift'] : null,
            date: (observation.payload.doc.data()['date']) ? new Date(observation.payload.doc.data()['date']['seconds'] * 1000) : null,
            duration: (observation.payload.doc.data()['duration']) ? observation.payload.doc.data()['duration'] : null,
            task: (observation.payload.doc.data()['task']) ? observation.payload.doc.data()['task'] : null,
            observations: observationDetails
          };
        });

        if (search && search !== '') {
          filteredObservations =  filteredObservations.filter(observation => {
            let foundInObservationDetail = false;

            observation.observations.map(obs => {
              if (!foundInObservationDetail && obs['description']) {
                foundInObservationDetail = obs['description'].toLowerCase().includes(search.toLowerCase());
              }
            });

            return (
              observation['department']['label'] && observation['department']['label'].toLowerCase().includes(search.toLowerCase()) ||
              observation['shift']['label'] && observation['shift']['label'].toLowerCase().includes(search.toLowerCase()) ||
              observation['editor']['fullname'] && observation['editor']['fullname'].toLowerCase().includes(search.toLowerCase()) ||
              observation['task'] && observation['task'].toLowerCase().includes(search.toLowerCase()) ||
              foundInObservationDetail
            );
          });
        }

        this.plantObservations = filteredObservations;

        this.userStats = {
          observationNumber: 0,
          totalObservationTime: 0
        };

        this.plantStats = {
          observationNumber: 0,
          totalObservationTime: 0
        };

        filteredObservations.map(observation => {
          this.plantStats.observationNumber++;
          this.plantStats.totalObservationTime += observation['duration'];

          if (observation['editor']['fullname'] === user[0]['fullname']) {
            this.userStats.observationNumber++;
            this.userStats.totalObservationTime += observation['duration'];
          }
        });

        return filteredObservations;
      })
    );

    this.isAdmin$ = this.userManagement.user.pipe(
      map(user => (user['admin'].indexOf(this.app.plantId) > -1))
    );

    this.userEmail$ = this.userManagement.user.pipe(
      map(user => user['email'])
    );
  }

  editObservation = (id?) => {
    this.router.navigate((id) ? ['edit/' + id] : ['new'], { relativeTo: this.route });
  }

  deleteObservation = (id) => {
    this.translate.get('DELETE_OBSERVATION')
      .subscribe(translation => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: { title: translation + ' ?' }
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response === 'y') {
            this.afs.collection('observations').doc(id).delete()
              .then(() => {
                this.translate.get('OBSERVATION_DELETED')
                  .subscribe(snackbarTranslation => {
                    this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
                  });
              });
          }
        });
      });
  }

  downloadCSV = () => {

    if (this.plantObservations.length > 0) {
      let csvObservations = 'data:text/csv;charset=utf-8,"id","editorName","editorEmail","attendantName","attendantEmail",'
                          + '"department","shift","date","duration","task",'
                          + '"type","category","situation","commitment","observation"\r\n';
      this.plantObservations.map(bos => {
        if (bos.observations.length > 0) {
          bos.observations.map(observation => {
            csvObservations +=
            '"' + bos.id + '",' +
            '"' + ((bos.editor) ? bos.editor.fullname : '') + '",' +
            '"' + ((bos.editor) ? bos.editor.email : '') + '",' +
            '"' + ((bos.attendant) ? bos.attendant.fullname : '') + '",' +
            '"' + ((bos.attendant) ? bos.attendant.email : '') + '",' +
            '"' + ((bos.department) ? bos.department.label : '') + '",' +
            '"' + ((bos.shift) ? bos.shift.label : '') + '",' +
            '"' + ((bos.date) ? bos.date : '') + '",' +
            '"' + ((bos.duration) ? bos.duration : '') + '",' +
            '"' + ((bos.task) ? bos.task : '') + '",' +
            '"' + ((observation.type) ? observation.type : '') + '",' +
            '"' + ((observation.category) ? observation.category : '') + '",' +
            '"' + ((observation.situation) ? observation.situation : '') + '",' +
            '"' + ((observation.commitment) ? observation.commitment : '') + '",' +
            '"' + ((observation.description) ? observation.description : '') + '"\r\n';
          });
        }
      });

      const encodedUri = encodeURI(csvObservations);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('hidden', 'true');
      link.setAttribute('download', 'observations.csv');
      link.innerHTML = 'Click Here to download';
      document.body.appendChild(link);
      link.click();
    }
  }
}
