import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ObservationListComponent } from './observation-list/observation-list.component';
import { ObservationEditComponent } from './observation-edit/observation-edit.component';
import { AuthGuardService } from '../authentication/auth-guard.service';

const routes: Routes = [
  { path: ':plantId/observation',
    canActivate: [AuthGuardService],
    children: [
      { path: '', component: ObservationListComponent },
      { path: 'new', component: ObservationEditComponent },
      { path: 'edit/:id', component: ObservationEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObservationRoutingModule { }
