import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore } from 'angularfire2/firestore';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  departmentFormGroup: FormGroup;
  departments: any[];
  managersNumber: boolean[];
  users: any[];

  constructor(private formBuilder: FormBuilder,
              private afs: AngularFirestore,
              private snackbar: MatSnackBar,
              private translate: TranslateService,
              private app: AppService) {
    this.departmentFormGroup = this.formBuilder.group({
      department: null
    });
  }

  ngOnInit() {
    this.afs.collection('plants').doc(this.app.plantId)
      .collection('departments', ref => ref.orderBy('label')).snapshotChanges().subscribe(departments => {
      this.departments = departments.map(department => {
        return {
          id: department.payload.doc.id,
          ...department.payload.doc.data()
        };
      });

      if (! this.departmentFormGroup.get('department').value) {
        this.departmentFormGroup.get('department').setValue(this.departments[0]['id']);
      }
    });

    this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges()
      .subscribe(dbUsers => {
        this.users = dbUsers.map(user => {
          return {
            id: user.payload.doc.id,
            ...user.payload.doc.data()
          };
        });
      });

    this.departmentFormGroup.get('department').valueChanges.subscribe(selectedDepartmentId => {
      Object.keys(this.departmentFormGroup.value)
        .filter(key => key.indexOf('manager') === 0)
        .map(key => this.departmentFormGroup.removeControl(key));

      const selectedDepartment = this.departments.filter(departement => departement['id'] === selectedDepartmentId)[0];

      if (selectedDepartment['managers'] && selectedDepartment['managers'].length > 0) {
        this.managersNumber = selectedDepartment['managers'].map(() => true);
        selectedDepartment['managers'].map((manager, index) => {
          this.departmentFormGroup.addControl('manager' + index, new FormControl());
          this.departmentFormGroup.get('manager' + index).setValue(manager['id']);
        });
      } else {
        this.managersNumber = [true];
        this.departmentFormGroup.addControl('manager0', new FormControl());
      }
    });
  }

  saveDepartment = () => {
    const managers = Object.keys(this.departmentFormGroup.value)
      .filter(key => key.indexOf('manager') === 0 && this.departmentFormGroup.get(key).value)
      // FIXME: Users set in observable. Value unstable.
      .map(key => this.users.filter(user => user.id === this.departmentFormGroup.get(key).value)[0])
      .map(user => {
        return {
          id: user.id,
          fullname: user.fullname,
          email: user.email
        };
      });

    const departmentId = this.departmentFormGroup.get('department').value;

    this.afs.collection('plants').doc(this.app.plantId)
      .collection('departments').doc(this.departmentFormGroup.get('department').value)
      .update({
        managers
      })
      .then(() => {
        this.translate.get('DEPARTMENT_UPDATED')
          .subscribe(snackbarTranslation => {
            this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
          });
      });
  }

  addManagerControl = () => {
    this.managersNumber.push(true);
    this.departmentFormGroup.addControl('manager' + (this.managersNumber.length - 1), new FormControl());
  }
}
