import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
