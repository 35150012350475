import { filter, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable()
export class UserManagementService {

  get user() {
    return this.afAuth.authState.pipe(
      filter(dbUser => (dbUser && typeof dbUser !== 'undefined')),
      switchMap(dbUser => this.afs.collection('users').doc(dbUser.uid).valueChanges())
    );
  }

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore) { }
}
