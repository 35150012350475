import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {
  @Input() name = 'user';
  @Output() selectionChange = new EventEmitter<any>();
  users$: Observable<any[]>;

  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.users$ = this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges().pipe(
      map(users => {
        return users.map(user => {
          return {
            id: user.payload.doc.id,
            ...user.payload.doc.data()
          };
        });
      })
    );

  }

  emitUser = (event) => {
    this.selectionChange.emit(event);
  }
}
