import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { map, startWith, distinctUntilChanged, delay } from 'rxjs/operators';
import { AngularFirestore } from 'angularfire2/firestore';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { combineLatest } from 'rxjs/observable/combineLatest';
import * as moment from 'moment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  userFormGroup: FormGroup;
  @Output() id = new EventEmitter<string>();
  users$;

  constructor(private afs: AngularFirestore,
              private formBuilder: FormBuilder,
              private snackbar: MatSnackBar,
              private translate: TranslateService) {
    this.userFormGroup = this.formBuilder.group({
      search: ''
    });
  }

  ngOnInit() {
    this.users$ = combineLatest(
      this.userFormGroup.get('search').valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        delay(650)
      ),
      this.afs.collection('users', ref => ref.orderBy('fullname')).snapshotChanges()
    ).pipe(
      map(([search, users]) => {
        let filteredUsers = users.map(user => {
          return {
            id: user.payload.doc.id,
            ...user.payload.doc.data()
          };
        });

        if (search && search !== '') {
          filteredUsers = filteredUsers.filter(user =>
            user['fullname'].toLowerCase().includes(search.toLowerCase()) ||
            user['email'].toLowerCase().includes(search.toLowerCase())
          );
        }

        return filteredUsers;
      })
    );
  }

  editUser = (id) => {
    this.id.emit(id);
  }

  deleteUser = (id) => {
    this.afs.collection('users').doc(id).delete()
      .then(() => {
        this.translate.get('USER_DELETED')
          .subscribe(snackbarTranslation => {
            this.snackbar.open(snackbarTranslation + '.', 'OK', { duration: 2000 });
          });
      });
  }
}
