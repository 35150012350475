import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class AppService {
  private _plantId: string;
  showProgressBar$: BehaviorSubject<boolean>;

  get plantId() {
    return (this._plantId) ? this._plantId : localStorage.getItem('plantId');
  }

  set plantId(plantId) {
    this._plantId = plantId;
  }

  constructor() {
    this.showProgressBar$ = new BehaviorSubject(false);
  }

  showProgressBar = (show: boolean = true) => this.showProgressBar$.next(show);
}
