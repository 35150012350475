import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule } from '../material-design/material-design.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ObservationRoutingModule } from './observation-routing.module';
import { ObservationListComponent } from './observation-list/observation-list.component';
import { ObservationEditComponent } from './observation-edit/observation-edit.component';

@NgModule({
  imports: [
    CommonModule,
    ObservationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    FlexLayoutModule,
    TranslateModule
  ],
  declarations: [ObservationListComponent, ObservationEditComponent]
})
export class ObservationModule { }
